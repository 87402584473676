// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

const customHostedRunnersOnboardingFreeOrgConfirmationText =
  // eslint-disable-next-line i18n-text/no-en
  'Are you sure that you would like to enable the beta features for GitHub-hosted runners for a free account?\nIt will also enable "custom_runners_allow_free_orgs" FF for the account'

on('click', '.js-checkbox-confirm-onboarding-free-org', function (e) {
  const toggle = e.currentTarget
  if (!(toggle instanceof HTMLInputElement)) return

  // no confirmation when disabling access
  if (!toggle.checked) return

  if (!confirm(customHostedRunnersOnboardingFreeOrgConfirmationText)) {
    e.preventDefault()
    toggle.checked = false
  }
})
