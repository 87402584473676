import {controller, target} from '@github/catalyst'
import {requestSubmit} from '../form'
import type {ModalDialogElement} from '@primer/view-components/app/components/primer/alpha/modal_dialog'

@controller
class AdvancedSecurityNewReposElement extends HTMLElement {
  @target checkbox: HTMLFormElement
  @target status: HTMLElement
  @target dialog: ModalDialogElement
  @target form: HTMLFormElement

  // Handle the checkbox changing state
  toggle(event: Event) {
    if (this.checkbox.checked) {
      // Show the dialog if they're enabling
      // Hide the status indicator - it may be shown from a previous submission,
      // but we don't want to give the impression that the current selection has
      // been submitted yet.
      this.status.hidden = true
      this.dialog.show()
      event.stopPropagation()
    } else {
      // For disable, just submit the form, but ensure the indicator is visible
      this.status.hidden = false
      requestSubmit(this.form)
    }
  }

  // Handle the buttons in the confirmation dialog

  confirm() {
    this.status.hidden = false
    requestSubmit(this.form)
    this.dialog.open = false
  }

  // clear the checkbox if the dialog is closed by anything other
  // than the confirm button (which won't fire this event)
  close() {
    this.checkbox.checked = false
  }
}
